export const query = {
    "target": "ENTITY",
    "fetch": [],
    "conditions": [
        {
            "type": "AND",
            "conditions": [
                {
                    "type": "HASFIELDWITHVALUE",
                    "field": "status",
                    "value": "active"
                }
            ]
        }
    ],
    "sorts": [
        {
            "field": "_metadata.createdTimestamp",
            "direction": "DESC"
        }
    ]
}