import React, {Component} from 'react'



export class  WidgetWrapperWithHeader extends Component {

    render() {
        return (
            <div>
            {this.props.children}
            </div>
        )
    }
}