import React, {Component} from 'react'
import {WidgetWrapperWithHeader} from "../../WidgetWrapperWithHeader"
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'
import {query} from "./query"
import { FaPen } from 'react-icons/fa';
const styles = (theme) => ({
    root:{}
})

export default class DoughnutWidget extends Component {

    constructor(props){
        super(props)
        this.axiosInstance = axios.create({
            baseURL: 'https://api.production.cloudios.flowfact-prod.cloud',
            timeout: 5000,
            headers: {'token': '1a9d422f-de10-4867-aa10-061db98c1773'}
        });
    }
    componentDidMount() {
        if(!this.state.cognitoToken){
            this.axiosInstance.get('/admin-token-service/public/adminUser/authenticate').
            then(result=>{
                console.log('result ',result)
                this.setState({cognitoToken:result.data})
                this.fetchData()
            }).catch(error=>{
                console.log(error)
            })
        }
        else{
            this.fetchData()
        }
    }

    state = {
        query:query,
        cognitoToken:null,
        showQuery:false,
        result:[],
        labels: [],
        datasets: [
        ]
    }
    onInputChange = (event)=>{
        console.log(event.target.value)
        this.setState({query:JSON.parse(event.target.value)})
    }
    fetchData = () =>{
        this.axiosInstance.post(`search-service/schemas/flat_purchase`,this.state.query,{
            headers: { 'Content-Type': 'application/json',"cognitoToken":this.state.cognitoToken },
        }).then(result=>{
            const data=[]
            console.log('result ',result.data)
            this.setState({result:result.data.entries})
            this.prepareData(result.data.entries)
        })
            .catch(error=>{

            })
    }
    prepareData = (entries) =>{

        const labels = []
        let data = []
        const result = {}
        entries.forEach(item=>{
            if(result[item.rooms.values[0]]){
                result[item.rooms.values[0]]=+1
            }
            else{
                result[item.rooms.values[0]]=1
            }
        })
        Object.keys(result).forEach(key=>{
            labels.push(key)
            data.push(result[key])
        })

        this.setState({labels:labels,datasets:[{
                label: 'Rainfall',                backgroundColor: [
                    '#00ccc0',
                    '#84ffc3',
                    '#4ae498',
                    '#dbecf8'
                ],
                borderColor: 'rgba(,0,0,1)', borderWidth: 2,
                data: data}]})
    }
    onPenClick = () =>{
        this.setState({
            showQuery:!this.state.showQuery
        })
        if(this.state.showQuery){
            this.setState({
                showQuery:false
            })
            this.fetchData()
        }
        else{
            this.setState({
                showQuery:true
            })
        }
    }
    render(){
        const {classes, className, title} = this.props
        const results = this.state.result.map(item=>{
            return (<div>{JSON.stringify(item)}</div>)
        })
        return(
            <WidgetWrapperWithHeader title = {title}>
                <div style={{background: 'white',padding:'20px',borderRadius: '10px'}}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                        {this.state.showQuery&&<div><input size={65}  type="search" value={JSON.stringify(this.state.query)} onChange={this.onInputChange} /></div>}
                        <div onClick={this.onPenClick} style={{cursor:'Pointer'}}> <FaPen /></div>
                    </div>
                    <div style={{fontSize:30,marginLeft:'20px'}}>
                    Pie Chart
                    </div>
                    <Doughnut data={this.state}  width={100}
                              height={50}/>
                </div>
            </WidgetWrapperWithHeader>
        )
    }
}

DoughnutWidget.propTypes = {
    className: PropTypes.string,
    readOnly:PropTypes.bool,
 //   entity: PropTypes.isRequired,
   // onUpdateEntityFields: PropTypes.func,
    title: PropTypes.string

}