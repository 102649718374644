import React, {Component} from 'react'
import {WidgetWrapperWithHeader} from "../../WidgetWrapperWithHeader"
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'
import './TextWidget.css'
import { FaPen } from 'react-icons/fa';
import {query} from "../DoughnutWidget/query";

const styles = (theme) => ({
    root:{}
})

export default class TextWidget extends Component {

    constructor(props){
        super(props)
        this.axiosInstance = axios.create({
            baseURL: 'https://api.production.cloudios.flowfact-prod.cloud',
            timeout: 5000,
            headers: {'token': '1a9d422f-de10-4867-aa10-061db98c1773'}
        });
    }
    componentDidMount() {
        if(!this.state.cognitoToken){
            this.axiosInstance.get('/admin-token-service/public/adminUser/authenticate').
            then(result=>{
                console.log('result ',result)
                this.setState({cognitoToken:result.data})
                this.fetchData()
            }).catch(error=>{
                console.log(error)
            })
        }
        else{
            this.fetchData()
        }
    }
    onInputChange = (event)=>{
        console.log(event.target.value)
        this.setState({query:JSON.parse(event.target.value)})
    }
    fetchData = () =>{
        this.axiosInstance.post(`search-service/schemas/flat_purchase`,this.state.query,{
            headers: { 'Content-Type': 'application/json',"cognitoToken":this.state.cognitoToken },
        }).then(result=>{
            console.log('result ',result.data)
            this.setState({result:result.data.entries})
        })
            .catch(error=>{
                console.log(error)
            })
    }
    onPenClick = () =>{
        this.setState({
            showQuery:!this.state.showQuery
        })
        if(this.state.showQuery){
            this.setState({
                showQuery:false
            })
            this.fetchData()
        }
        else{
            this.setState({
                showQuery:true
            })
        }
    }
    state = {
        cognitoToken:null,
        result:[],
        data:null,
        showQuery:false,
        query:query
    }
    render(){
        const {classes, className, title} = this.props;
        return(
            <WidgetWrapperWithHeader title={title}>
                <div className={'textWidget-widgetContainer'}>
                    <div className={'container'}>
                        <div className={'dataContainer'}>{this.state.result.length}</div>
                        <div className={'textContainer'}>All available flats</div>
                    </div>
                    {this.state.showQuery &&
                    <div className={"text-widget-input"}>
                        <input
                            size={65} type="search"
                            value={JSON.stringify(this.state.query)}
                            onChange={this.onInputChange}/>
                    </div>}
                    <FaPen class={'editIcon'} onClick={this.onPenClick}/>
                </div>
            </WidgetWrapperWithHeader>
        )
    }
}

TextWidget.propTypes = {
    className: PropTypes.string,
    readOnly:PropTypes.bool,
    //   entity: PropTypes.isRequired,
    // onUpdateEntityFields: PropTypes.func,
    title: PropTypes.string

}
