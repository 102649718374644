import React from 'react';
import DoughnutWidget from "./Widgets/DoughnutWidget/DoughnutWidget"
import TextWidget from "./Widgets/TextWidget/TextWidget"
import './App.css';
import w1 from "./w1.png"

function App() {
    return (
        <div className="App">
            <div className={'widgetContainer'}>
                <img src={w1} className={"image"} alt="Logo"/>
                <TextWidget/>
                <TextWidget />
                <div style={{width:460,height:175}}>
                    <DoughnutWidget entity={{}} entityViewType={"entityViewType"}/>
                </div>
            </div>

        </div>
    );
}

export default App;
